<template>
  <div style="background-color: white;height: auto;">
    <v-toolbar dark color="primary">
      <v-btn icon dark v-on:click="$emit('close-dialog')">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title v-if="user._id"
        >Cập Nhật Thông Tin Nhân Viên</v-toolbar-title
      >
      <v-toolbar-title v-else>Tạo Nhân Viên Mới</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container
      fluid
      flat
      grid-list-xs
      style="padding:2px;width:600px;height: auto;"
      v-bind:style="{ backgroundColor: $root.pageBackColor }"
    >
      <v-row align="center">
        <v-col class="ma-0 pa-2" cols="12">
          <div style="width: 500px;">
            <v-card flat class="pa-3 mt-4" style="border: 1px solid #AAAAAA;">
              <v-card-title class="px-4 py-1" v-if="!user._id">
                Tạo Nhân Viên Mới
              </v-card-title>
              <v-card-title class="px-4 py-1" v-else>
                Cập Nhật Thông Tin Nhân Viên
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="editUserForm"
                  v-model="formValid"
                  :lazy-validation="true"
                >
                  <v-text-field
                    style="max-width:500px;margin-top:16px;margin-right:80px;"
                    v-model="user.user_code"
                    :rules="[rules.required]"
                    label="Mã nhân viên *"
                    type="text"
                    outlined
                    dense
                  >
                  </v-text-field>
                  <v-text-field
                    style="max-width:500px;margin-top:16px;margin-right:80px;"
                    v-model="user.fullname"
                    :rules="[rules.required, rules.fullname]"
                    label="Tên *"
                    type="text"
                    outlined
                    dense
                  >
                  </v-text-field>
                  <v-select
                    style="max-width:500px;margin-top:16px;margin-right:80px;"
                    v-model="user.department"
                    :items="departments"
                    item-value="_id"
                    item-text="name"
                    :rules="[rules.required]"
                    label="Phòng ban *"
                    outlined
                    dense
                  ></v-select>
                  <v-text-field
                    v-if="!user._id"
                    style="max-width:500px;margin-top:16px;margin-right:80px;"
                    v-model="user.work_at"
                    :rules="[]"
                    label="Nơi làm việc"
                    type="text"
                    outlined
                    dense
                  >
                  </v-text-field>
                  <v-text-field
                    v-if="!user._id"
                    style="max-width:500px;margin-top:16px;margin-right:80px;"
                    v-model="user.email"
                    :rules="[]"
                    label="Mail"
                    type="text"
                    outlined
                    dense
                  >
                  </v-text-field>
                  <v-text-field
                    v-if="!user._id"
                    class="mt-3"
                    style="max-width:500px;margin-top:16px;margin-right:80px;"
                    v-model="user.pwd"
                    :rules="[]"
                    label="Mật khẩu"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    outlined
                    dense
                  >
                  </v-text-field>
                  <v-text-field
                    style="max-width:500px;margin-top:16px;margin-right:80px;"
                    v-model="user.mobile"
                    label="Mobile"
                    type="text"
                    outlined
                    dense
                  >
                  </v-text-field>
                  <div class="mt-2">
                    Ảnh đại diện
                  </div>
                  <div class="pb-1">
                    <input
                      type="file"
                      style="display: none"
                      ref="imageInput"
                      accept="*/*"
                      @change="handleFileUpload"
                    />

                    <v-btn
                      @click="startPickImage"
                      class="ml-2"
                      color="teal"
                      outlined
                      style="min-width:20px;width:46px;"
                    >
                      <v-icon>
                        add_photo_alternate
                      </v-icon>
                    </v-btn>
                  </div>
                  <div>
                    <v-img
                      width="192"
                      height="192"
                      contain
                      style="border:1px solid #DDDDDD;"
                      :src="
                        user.avatar
                          ? user.avatar
                          : '/imgs/user-placehold.jpg'
                      "
                    />
                  </div>
                  <div
                    class="mt-3"
                    v-if="1 == 2 && user.role != 'ninAnd033'"
                    style="max-width:500px;margin-top:0px;color:#666666;"
                  >
                    <div>User Role</div>
                    <v-btn
                      dark
                      rounded
                      color="teal"
                      style="text-transform:none;margin: 4px 4px;font-size:12px;width:80px;min-width:50px;height:28px;"
                      v-on:click="user.role = 'tugets050'"
                      :outlined="user.role != 'tugets050'"
                    >
                      Guest
                    </v-btn>
                    <v-btn
                      dark
                      rounded
                      color="blue"
                      style="text-transform:none;margin: 4px 4px;font-size:12px;width:80px;min-width:50px;height:28px;"
                      v-on:click="user.role = 'Perio020'"
                      :outlined="user.role != 'Perio020'"
                    >
                      Operator
                    </v-btn>
                    <v-btn
                      v-if="1 == 2"
                      dark
                      rounded
                      color="orange"
                      style="text-transform:none;margin: 4px 4px;font-size:12px;width:130px;min-width:50px;height:28px;"
                      v-on:click="user.role = 'neergeen040'"
                      :outlined="user.role != 'neergeen040'"
                    >
                      Service Engineer
                    </v-btn>
                    <v-btn
                      dark
                      rounded
                      color="red"
                      :disabled="role != 'ASnind010'"
                      style="text-transform:none;margin: 4px 4px;font-size:12px;width:80px;min-width:50px;height:28px;"
                      v-on:click="user.role = 'ninAnd033'"
                      :outlined="user.role != 'ninAnd033'"
                    >
                      Administrator
                    </v-btn>
                  </div>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="text-capitalize bold"
                  color="grey darken-1"
                  @click.native="$emit('close-dialog')"
                  style="font-size: 16px;"
                  >Cancel</v-btn
                >

                <v-btn
                  class="text-capitalize bold"
                  text
                  color="primary"
                  @click.native="saveUser"
                  style="font-size: 16px;"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// const moment = require("moment");
import { mapGetters } from "vuex";

export default {
  props: ["show", "user", "departments"],
  computed: {
    ...mapGetters({
      userId: "userId",
      token: "token",
      role: "role",
      fullname: "getFullName",
    }),
  },
  data() {
    return {
      formValid: "",
      showPassword: false,
      rules: {
        required: (value) => !!value || "* Required",
        passRule: (v) => (v && v.length >= 6) || "Min 6 chars",
        username: (v) => (v && v.length >= 6) || "Min 6 chars",
        fullname: (v) => (v && v.length >= 3) || "Min 3 chars",
        email: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      },
    };
  },
  watch: {
    show(val) {
      if (val) {
        setTimeout(() => {
          this.resetValidationForm();
        }, 50);
      } else {
        this.resetValidationForm();
      }
    },
  },
  methods: {
    startPickImage() {
      this.$refs.imageInput.click();
    },
    handleFileUpload: function() {
      var file = this.$refs.imageInput.files[0];
      if (file !== undefined) {
        var self = this;
        self.result = "";
        let formData = new FormData();
        formData.append("file", file);
        self.axios
          .post("https://file.ponotek.com/uploads", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(res) {
            self.user.avatar = res.data.content;
          })
          .catch(function(err) {
            console.log(err);
          });
      }
    },
    saveUser() {
      if (!this.validateForm()) return;
      if (this.user._id) {
        this.updateUser();
      } else {
        this.createNewUser();
      }
    },
    updateUser() {
      var self = this;
      var path = this.$root.apiUser + "/users/update/" + self.user._id;
      this.axios
        .put(path, self.user, { headers: { "x-auth": self.token } })
        .then((response) => {
          if (response.data.status === "OK") {
            alert("Update User " + self.user.fullname + " success!");
            self.$emit("close-dialog-and-refresh-users");
          } else {
            alert("Error: " + response.data.message);
          }
        });
    },
    createNewUser() {
      var self = this;
      var path = this.$root.apiUser + "/users/create";
      self.user.customer_id = this.$root.apiUserCustomId;

      this.axios
        .post(path, self.user, { headers: { "x-auth": self.token } })
        .then((response) => {
          if (response.data.status === "OK") {
            alert("Create new user " + self.user.fullname + " success!");
            self.$emit("close-dialog-and-refresh-users");
          } else {
            alert("Error: " + response.data.message);
          }
        })
        .catch((err) => {
          alert("Error: " + err.message);
        });
    },
    validateForm() {
      return this.$refs.editUserForm.validate();
    },
    resetForm() {
      this.$refs.editUserForm.reset();
    },
    resetValidationForm() {
      this.$refs.editUserForm.resetValidation();
    },
  },
};
</script>
<style></style>
