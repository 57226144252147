<template>
  <v-container style="max-width:1200px;">
    <v-row>
      <v-col cols="12">
        <v-card flat style="border-radius:8px;min-height:320px;">
          <v-card-title
            class="py-2 px-2"
            dark
            style="color:teal;border-bottom: 1px solid teal;"
          >
            <v-btn
              color="teal"
              dark
              class="text-none mr-2 mb-2"
              @click="showCreateUser()"
            >
              <v-icon small class="mr-2">mdi-plus</v-icon>
              Thêm nhân viên
            </v-btn>
            <template v-if="selected_users && selected_users.length > 0">
            <!--
              <v-btn
                color="#DD0011"
                dark
                class="text-none mr-2 mb-2"
                @click="lockUsers('locks')"
              >
                <v-icon small class="mr-2">mdi-lock-outline</v-icon>
                Khóa
              </v-btn>
              <v-btn
                color="green"
                dark
                class="text-none mr-2 mb-2"
                @click="lockUsers('unlocks')"
              >
                <v-icon small class="mr-2"
                  >mdi-lock-open-variant-outline</v-icon
                >
                Mở Khóa
              </v-btn>
              <v-btn
                color="green"
                dark
                class="text-none mr-2 mb-2"
                @click="showSendEmailDialog"
              >
                <v-icon small class="mr-2">
                  mdi-email
                </v-icon>
                Gửi email
              </v-btn>
              -->
            </template>
            <v-spacer />
            <v-text-field
            class="mb-2"
              v-model="searchString"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              outlined
              dense
              single-line
              hide-details
              style="max-width:320px;"
            ></v-text-field>
          </v-card-title>

          <v-card-text class="pa-0 pt-2" style="min-height:300px;">
            <v-data-table
              flat
              v-model="selected_users"
              :headers="headers"
              :items="displayUsersAfterFilter"
              :loading="loading"
              loading-text="Loading... Please wait"
              no-data-text="Chưa có nhân viên nào"
              item-key="_id"
              :mobile-breakpoint="0"
              :items-per-page="pageSize"
              show-select
              dense
              class="elevation-0"
              :search="searchString"
              :page.sync="usersPage"
              @page-count="pageCount = $event"
              hide-default-footer
            >
              <template v-slot:item.fullname="{ item }">
                <a :href="'/users/' + item._id">
                  {{ item.fullname }}
                </a>
              </template>
              <template v-slot:item.createdDate="{ item }">
                {{ $moment(item.createdDate).format("HH:mm DD/MM/YY") }}
              </template>
              <!--               
              <template v-slot:item.total_points="{ item }">
                {{ item.total_points.toLocaleString() }} đ
              </template> -->
              <template
                v-slot:item.lock="{ item }"
                >
                <v-icon v-if="item.lock">
                    mdi-lock
                </v-icon>
              </template>
            </v-data-table>
            <div v-if="pageCount>1"
              class="text-center pt-2">
              <v-pagination
                  v-model="usersPage"
                  :length="pageCount"
                  ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-dialog v-model="email_dialog" width="640">
        <v-card>
          <v-card-title style="background-color:teal;color:white" class="py-1">
            Gửi email
          </v-card-title>

          <v-card-text class="pa-4">
            <v-text-field
              outlined
              dense
              class=""
              label="Tựa đề email"
              v-model="email_title"
            ></v-text-field>

            <vue-editor v-model="email_content"></vue-editor>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="email_dialog = false">
              Hủy
            </v-btn>
            <v-btn text color="teal" @click="sendEmailToUser">
              Gửi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogEditUser"
        fullscreen
        hide-overlay
        persistent
        width="900"
      >
        <edit-user
          :show="dialogEditUser"
          :user="curEditUser"
          :departments="departments"
          v-on:close-dialog-and-refresh-users="
            dialogEditUser = false;
            fetchUsers()
          "
          v-on:close-dialog="dialogEditUser = false"
        />
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import EditUser from "@/components/User/EditUser.vue";

export default {
  computed: {
    ...mapGetters({
      token: "token",
    }),
    displayUsersAfterFilter() {
      let result = [];
      this.processAfterFetchDataUsers();
      if (!this.users) return [];
      result = JSON.parse(JSON.stringify(this.users));
      return result;
    },
  },
  components: {
    VueEditor,
    EditUser,
  },
  data() {
    return {
      roles: ['user-manager', 'admin'],
      pageCount: 0,
      usersPage: 1,
      users: [],
      selected_users: [],
      departments: [],
      pageSize: 20,
      loading: false,
      searchString: "",
      headers: [
        { text: "Tên", align: "start", sortable: true, value: "fullname" },
        {
          text: "Mã NV",
          align: "start",
          sortable: true,
          value: "user_code",
          width: 100,
        },
        {
          text: "Phòng ban",
          align: "start",
          sortable: true,
          value: "department_name",
          width: 260,
        },
        {
          text: "Chức vụ",
          align: "start",
          sortable: true,
          value: "position",
          width: 260,
        },
        {
          text: "Ngày đăng ký",
          align: "start",
          sortable: true,
          value: "createdDate",
          width: 140,
        },
        // { text: "Email", align: "start", sortable: false, value: "email" },
        // {
        //   text: "Mobile",
        //   align: "start",
        //   sortable: false,
        //   value: "mobile",
        //   width: 140,
        // },
        // { text: 'Khóa', align: 'start', sortable: true, value: 'lock', width: 80},
      ],
      email_title: "",
      email_content: "",
      email_dialog: false,
      dialogEditUser: false,
      curEditUser: {},
    };
  },
  methods: {
    showCreateUser() {
      console.log("showCreateUser");
      this.curEditUser = {
        fullname: "",
        role: "Guest",
        email: "",
        mobile: "",
        pwd: "",
        work_at: ""
      };
      this.dialogEditUser = true;
    },
    editClient(item) {
      this.curEditUser = item;
      this.dialogEditUser = true;
    },
    lockUsers(lockFunction) {
      // console.log(this.selected_users)
      if (!this.selected_users || this.selected_users.length <= 0) return;
      let self = this;
      this.axios
        .put(
          self.$root.apiUser + "/users/" + lockFunction,
          {
            site_id: this.$root.apiUserCustomId,
            ids: this.selected_users.map((u) => u._id),
          },
          { headers: { "x-auth": this.token } }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            self.fetchUsers();
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showSendEmailDialog() {
      this.email_title = "";
      this.email_content = "";
      this.email_dialog = true;
    },
    sendEmailToUser() {
      if (!this.selected_users || this.selected_users.length <= 0) return;
      let self = this;
      this.axios
        .put(
          self.$root.apiUser +
            "/users/sendEmail/" +
            this.selected_users.map((u) => u._id).join(";"),
          { title: this.email_title, htmlContent: this.email_content },
          { headers: { "x-auth": this.token } }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            self.email_dialog = false;
            alert("Đã gửi email xong");
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    processAfterFetchDataUsers() {
      if (this.users && this.users.length > 0) {
        this.users.forEach((item) => {
          //Departments
          if (!this.departments.find((i) => i._id == item.department)) {
            item.department_name = "-";
          } else {
            item.department_name = this.departments.find(
              (i) => i._id == item.department
            ).name;
          }
        });
      }
    },
    fetchUsers() {
      let self = this;
      self.selected_users = [];
      let pathFetchDataUsers =
        self.$root.apiUser + "/users/list/" + self.$root.apiUserCustomId;
      let pathFetchDataDepartments =
        self.$root.apiUser + "/depts/list/" + self.$root.apiUserCustomId;

      self.loading = true;
      Promise.all([
        self.axios.get(pathFetchDataUsers),
        self.axios.get(pathFetchDataDepartments),
      ])
        .then((responseArray) => {
          console.log("Promise ALL",responseArray );
          if (responseArray[0].data.status == "OK") {
            self.users = responseArray[0].data.content.items
            console.log(self.users)
          }
          if (responseArray[1].data.status == "OK") {
            self.departments = responseArray[1].data.content.items
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // self.processTotalReport();
          self.loading = false;
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchUsers();
      // this.fetchDepartments();
    });
  },
};
</script>

<style></style>
